import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {ProgressTracker} from '@partssourceinc/react-ui-core';

import CmsText from 'components/cms/CmsText';
import * as DashboardStore from 'stores/Dashboard';
import * as UserStore from 'stores/User';
import {logEvent, translateHomeScreen} from 'utility';
import 'less/taskbar.less';

const PsPlusIcon = styled.img`
    height: 14px;
`;

const PsPlusTitle = styled.div`
    height: 14px;
`;

const PlusItem = styled.div`
    white-space: nowrap;
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 16px;
    margin-right: 10px;
    cursor: pointer;
    margin-left: 2px;
`;
@withRouter
@connect(state => ({user: state.user, configuration: state.system.configuration}), DashboardStore.actionCreators)
export default class TaskBar extends React.Component {
    state = {
        items: [],
        program: null,
    };

    getProgram = async () => {
        const {user: {facilities}} = this.props;
        const facilityIds = facilities.map((f) => f.facilityId).join('|');
        const response = await axios.post(`/ShoppingService/api/v1/company/program`, {facilityIds});
        this.setState({program: response.data});
    }

    componentDidMount() {
        axios
            .get(`/ShoppingService/api/v1/account/tasks`)
            .then(response => this.setState({items: response.data.items}));

        this.getProgram();
    }

    handleClick = (item) => {
        const {history} = this.props;
        history.push(item.url);

        logEvent('TASKBAR CLICK', {
            'Name': item.caption,
            'Descriptor': parseInt(item.display, 10),
        });
    };

    renderPsPlusItem = (name) => {
        const {program} = this.state;

        return (<PlusItem
            key={`task_bar_item_ps_plus_item`}
            className="taskbar_item taskbar_item--loading"
            onClick={() => this.handleClick({
                url: '/psplus',
                caption: 'ps plus',
                display: program.totalSpend,
            })}>
            <div style={{display: 'flex'}}>
                <div>
                    <div className="taskbar_item_description">{name}</div>
                    <PsPlusIcon src={require('/images/psplus/plus-program.png')} />
                </div>
                {program && <div style={{width: '158px', marginLeft: '16px'}}>
                    <ProgressTracker
                        small={true}
                        spendAmount={program.totalSpend || 0}
                        targetOne={{
                            label: '5% Rebate',
                            amount: program.spendTargetOne,
                        }}
                        targetTwo={{
                            label: '10% Rebate',
                            amount: program.spendTargetTwo,
                        }} />
                </div>}
            </div>
        </PlusItem>)
    }

    renderItem = (item, index) => (
        <div
            key={`task_bar_item_${index}`}
            className="taskbar_item taskbar_item--loading"
            style={{cursor: 'pointer'}}
            onClick={() => this.handleClick(item)}
        >
            <div className="taskbar_item_title">{item.caption}</div>
            <div className="taskbar_item_description">{item.display}</div>
        </div>
    );

    render() {
        const {user: {info, companyLogo, settings: {displayProLogo, hasPSPlusAccess, hasDashboardv2Access, defaultHomeScreen}}, 
            configuration: {features: {dashboard}}} = this.props;
        const {items = []} = this.state;
        const defaultHomePage = translateHomeScreen(defaultHomeScreen, hasDashboardv2Access);
        const dashboardPath = hasDashboardv2Access ? '/command-center' : defaultHomePage;
        
        return (
            <div className="taskbar">
                {companyLogo && <div className="taskbar_item" style={{marginRight: '10px'}}>
                    <img src={`data:image/jpeg;base64,${companyLogo}`} alt="" />
                </div>}

                {hasPSPlusAccess && this.renderPsPlusItem(`${_.startCase(info.firstName.toLowerCase())} ${_.startCase(info.lastName.toLowerCase())}`)}
                
                {!hasPSPlusAccess && <div className="taskbar_item" style={{wordWrap: 'break-word', maxWidth: '145px', marginRight: '60px'}}>
                    <div className="lbl taskbar_item_name">
                        {dashboard 
                            ? (<Link to={dashboardPath}>{info.firstName.toLowerCase()} {info.lastName.toLowerCase()}</Link>)
                            : `${info.firstName.toLowerCase()} ${info.lastName.toLowerCase()}`
                        }
                    </div>
                    {displayProLogo && <div className="taskbar_item_account">Pro Account</div>}
                </div>}

                {items.length > 0 && items.map((item, index) => this.renderItem(item, index))}
                {items.length === 0 && <div className="taskbar_item">
                    <span className="taskbar_item_empty">
                        <CmsText resourceKey="taskBar.emptyText" />
                    </span>
                </div>}
            </div>
        );
    }
}
